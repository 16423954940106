exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-completed-course-list-js": () => import("./../../../src/templates/completedCourseList.js" /* webpackChunkName: "component---src-templates-completed-course-list-js" */),
  "component---src-templates-course-list-js": () => import("./../../../src/templates/courseList.js" /* webpackChunkName: "component---src-templates-course-list-js" */),
  "component---src-templates-courses-view-js": () => import("./../../../src/templates/CoursesView.js" /* webpackChunkName: "component---src-templates-courses-view-js" */),
  "component---src-templates-dynamic-article-js": () => import("./../../../src/templates/dynamicArticle.js" /* webpackChunkName: "component---src-templates-dynamic-article-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pipe-specialist-list-js": () => import("./../../../src/templates/pipeSpecialistList.js" /* webpackChunkName: "component---src-templates-pipe-specialist-list-js" */),
  "component---src-templates-pipe-specialist-view-js": () => import("./../../../src/templates/pipeSpecialistView.js" /* webpackChunkName: "component---src-templates-pipe-specialist-view-js" */)
}

